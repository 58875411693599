import React from "react";
import Navbar from "components/Nav/Navbar";

export default function PageContainer({ children }) {
  return (
    <div>
      <div className="fixed w-full z-40">
        <Navbar />
      </div>
      <div className="h-14"></div>
      {children}
    </div>
  );
}
