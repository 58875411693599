import { useState, useEffect } from "react";
import { useAuth } from "context/auth-context";

export default function useFetch(endpoint, options = {}) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const callAPI = async () => {
      const token = getToken();
      try {
        if (token) {
          options.headers = {
            Authorization: `Bearer ${token}`,
            ...(options.headers || {}),
          };
        }
        const response = await fetch(endpoint, options);
        const data = await response.json();

        setData(data);
      } catch (e) {
        setError(e);
      }
    };
    callAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, error];
}
