import React from "react";

const Form = (props) => <form {...props} />;

function Label({ children }) {
  return (
    <div className="block text-sm font-regular text-gray-500 my-1">
      {children}
    </div>
  );
}

function InlineLabel({ children, label }) {
  return (
    <label className="inline-flex items-center">
      {children}
      <span className="ml-2">{label}</span>
    </label>
  );
}

const TextInput = React.forwardRef(({ as, error, ...rest }, ref) => {
  if (as === "textarea") {
    return (
      <textarea
        ref={ref}
        className={`block w-full sm:text-sm px-2 py-2 h-56 bg-gray-100 border-0 ${
          error
            ? "border-red-400 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500"
            : ""
        }`}
        autoComplete="off"
        {...rest}
      />
    );
  }

  return (
    <input
      type="text"
      ref={ref}
      className={`block w-full sm:text-sm px-2 py-2 bg-gray-100 border-0 ${
        error
          ? "border-red-400 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500"
          : "border-gray-300"
      }`}
      autoComplete="off"
      {...rest}
    />
  );
});

const Select = React.forwardRef(({ name, error, ...rest }, ref) => (
  <select
    className={`block w-full py-2 px-2 border bg-white sm:text-sm ${
      error
        ? "border-red-400 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500"
        : "border-gray-300"
    }`}
    name={name}
    ref={ref}
    {...rest}
  />
));

const Checkbox = React.forwardRef((props, ref) => (
  <input
    type="checkbox"
    className="h-4 w-4 border-gray-300"
    ref={ref}
    {...props}
  />
));

Form.Label = Label;
Form.InlineLabel = InlineLabel;
Form.Select = Select;
Form.Checkbox = Checkbox;
Form.TextInput = TextInput;

export default Form;
