import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Button from "components/Common/Button";
import Form from "components/Common/Form";
import Tabs from "components/Common/Tabs";
import Checkbox from "components/Form/Checkbox";
import DateRange from "components/Form/DateRange";
import FileUpload from "components/Form/FileUpload";
import TextInput from "components/Form/TextInput";
import I18nInput from "components/Form/I18nInput";
import Select from "components/Form/Select";
import InputArray from "components/Form/InputArray";
import { useCreateSoapbox, useUpdateSoapbox } from "utils/soapboxes";
import { useI18n } from "utils/useI18n";

export const defaultValues = {
  id: "",
  headerImage: "",
  showXButton: false,
  includeIAPFooter: false,
  showDesignAward: false,
  centerAlignTitle: false,
  useCompactVerticalSpacing: false,
  title: { value: "" },
  text: { value: "" },
  trigger: "Startup",
  conditionals: {
    subscribers: true,
    build: "",
    numExports: "",
    numSessions: "",
  },
};

function useSoapboxOffersSelection() {
  const { watch, setValue } = useFormContext();
  const offers = watch("offers");
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (!offers || offers.length === 0) return;
    const preselected = offers.filter((item) => item.preselected);
    if (preselected.length === 0) {
      setValue("offers[0].preselected", true);
      setSelectedIndex(0);
    } else if (preselected.length > 1) {
      setValue(`offers[${selectedIndex}].preselected`, false);
    } else {
      setSelectedIndex(offers.findIndex((item) => item.preselected));
    }
  }, [offers, setValue, selectedIndex]);
}

const SoapboxForm = () => {
  const history = useHistory();
  const { locale, setLocale, availableLocales } = useI18n();
  const { mutate: updateSoapbox } = useUpdateSoapbox();
  const { mutate: createSoapbox } = useCreateSoapbox({
    onSuccess: (data) => {
      history.push(`/soapboxes/${data.id}`);
    },
  });
  const { handleSubmit, register } = useFormContext();
  useSoapboxOffersSelection();

  function onSubmit(data) {
    if (data.id) {
      updateSoapbox(data);
    } else {
      createSoapbox(data);
    }
  }

  return (
    <div className="min-h-full max-h-full relative">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-scroll absolute inset-0 mb-14 p-2">
          <Tabs>
            <Tabs.Tab label="Content">
              <input type="hidden" name="id" ref={register()} />
              <div className="mb-4">
                <Form.Label>Title</Form.Label>
                <I18nInput name="title" placeholder="title" required />
              </div>
              <div className="mb-4">
                <Form.Label>Text</Form.Label>
                <I18nInput name="text" textarea />
              </div>
              <div className="mb-4">
                <Form.Label>Header image</Form.Label>
                <FileUpload name="headerImage" />
              </div>

              <div className="mb-4">
                <InputArray
                  name="content"
                  schema={{
                    title: { type: "i18nText", required: true },
                    text: { type: "i18nText", required: true },
                    image: { type: "file" },
                  }}
                  itemLabel="Block"
                />
              </div>

              <div className="mb-4">
                <InputArray
                  name="carousel"
                  schema={{
                    title: { type: "i18nText", required: true },
                    video: { type: "file" },
                    image: { type: "file" },
                  }}
                  itemLabel="Carousel item"
                />
              </div>

              <div className="mb-4">
                <InputArray
                  name="offers"
                  schema={{
                    productIdentifier: { type: "text", required: true },
                    discountText: { type: "i18nText" },
                    preselected: { type: "checkbox" },
                  }}
                  itemLabel="Offer"
                />
              </div>

              <div className="mb-4">
                <InputArray
                  name="buttons"
                  schema={{
                    style: {
                      type: "select",
                      options: [
                        "Primary",
                        "Secondary",
                        "PrimaryUnderlined",
                        "SecondaryUnderlined",
                      ],
                    },
                    action: {
                      type: "select",
                      options: ["Dismiss", "URL", "Purchase"],
                    },
                    url: { type: "text" },
                    title: { type: "i18nText", required: true },
                  }}
                  itemLabel="Add button"
                />
              </div>
              <div className="mb-4">
                <Form.InlineLabel label="Show dismiss button">
                  <Checkbox name="showXButton" />
                </Form.InlineLabel><br />
                <Form.InlineLabel label="Include IAP Footer">
                  <Checkbox name="includeIAPFooter" />
                </Form.InlineLabel><br />
                <Form.InlineLabel label="Show Design Award">
                  <Checkbox name="showDesignAward" />
                </Form.InlineLabel><br />
                <Form.InlineLabel label="Center Align Title">
                  <Checkbox name="centerAlignTitle" />
                </Form.InlineLabel><br />
                <Form.InlineLabel label="Use Compact Vertical Spacing">
                  <Checkbox name="useCompactVerticalSpacing" />
                </Form.InlineLabel><br />
              </div>
            </Tabs.Tab>
            <Tabs.Tab label="Config">
              <div className="mb-4">
                <Form.Label>Trigger</Form.Label>
                <Select name="trigger" required>
                  <option>Startup</option>
                  <option>Paywall</option>
                </Select>
              </div>
              <div className="mb-4">
                <Form.Label>Build</Form.Label>
                <TextInput name="conditionals.build" />
              </div>
              <div className="mb-4">
                <Form.Label>Number of Exports</Form.Label>
                <TextInput name="conditionals.numExports" />
              </div>
              <div className="mb-4">
                <Form.Label>Number of Sessions</Form.Label>
                <TextInput name="conditionals.numSessions" />
              </div>
              <div className="mb-4">
                <Form.Label>Date Range</Form.Label>
                <DateRange name="conditionals.dateRange" />
              </div>
              <div className="mb-4">
                <Form.InlineLabel label="Visible to subscribers">
                  <Checkbox name="conditionals.subscribers" defaultChecked />
                </Form.InlineLabel>
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
        <div className="absolute flex items-center justify-between bottom-0 w-full px-2 py-2 bg-gray-50">
          <div className="text-sm">
            View in
            <select
              value={locale}
              onChange={(event) => setLocale(event.target.value)}
              className="bg-gray-50 border-0 text-sm"
            >
              {availableLocales.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </div>
  );
};

export default SoapboxForm;
