import React from "react";
import { Link, NavLink as BaseNavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../logo.svg";

function NavLink(props) {
  return (
    <BaseNavLink
      className="md:text-sm text-gray-500 px-1 py-2 md:py-1 hover:text-gray-900 hover:no-underline"
      activeClassName="md:border-b-2 border-gray-200"
      {...props}
    />
  );
}

function Navbar() {
  const [menuActive, setMenuActive] = React.useState(false);

  function handleNavLinkClick() {
    setMenuActive(false);
  }

  return (
    <nav className="bg-white shadow-sm">
      <div className="mx-3 md:mx-4">
        <div className="flex items-center justify-between h-14">
          <button
            className="md:hidden bg-gray-100 p-1 rounded-md focus:outline-none"
            onClick={() => setMenuActive(!menuActive)}
          >
            {menuActive ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M4 8h16M4 16h16"
                />
              </svg>
            )}
          </button>
          <div className="flex items-center">
            <Link
              to="/"
              className="flex space-x-2 items-center flex-shrink-0 mr-4"
            >
              <Logo />
              <span className="text-xl hidden md:block">Darkroom Admin</span>
            </Link>

            <div
              className={`${
                menuActive
                  ? "absolute bg-white w-full left-0 top-0 mt-14 shadow-sm"
                  : "hidden md:block"
              }`}
            >
              <div className="flex flex-col py-2 px-3 md:px-0 md:py-0 md:flex-row items-baseline md:space-x-4">
                <NavLink to="/builds" onClick={handleNavLinkClick}>
                  Builds
                </NavLink>
                <NavLink to="/soapboxes" onClick={handleNavLinkClick}>
                  Soapboxes
                </NavLink>
                <NavLink to="/megaphones" onClick={handleNavLinkClick}>
                  Megaphones
                </NavLink>
              </div>
            </div>
          </div>
          <div className="">{/* TODO: add login avatar/name */}</div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
