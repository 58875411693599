import React from "react";
import { Link, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import { useMegaphone } from "utils/megaphones";
import { I18nProvider } from "utils/useI18n";
import usePrevious from "utils/usePrevious";
import MegaphoneList from "components/Megaphone/MegaphoneList";
import MegaphoneForm, {
  defaultValues,
} from "components/Megaphone/MegaphoneForm";
import PageContainer from "components/PageContainer";
import MegaphoneFormPreview from "components/Megaphone/MegaphoneFormPreview";

function Megaphones() {
  const { id } = useParams();
  const megaphone = useMegaphone(id);

  const previousMegaphone = usePrevious(megaphone);
  const { reset, ...formMethods } = useForm();

  const isDetailPage = id !== undefined;
  const isNewPage = id === "new";

  React.useEffect(() => {
    if (id === "new") {
      reset(defaultValues);
    }
  }, [reset, id]);

  React.useEffect(() => {
    if (megaphone?.id !== previousMegaphone?.id) {
      reset(megaphone);
    }
  }, [reset, megaphone, previousMegaphone]);

  return (
    <PageContainer>
      <FormProvider {...formMethods}>
        <I18nProvider>
          <div className="flex bg-gray-100 max-h-screen -mt-14 pt-14 overflow-hidden">
            <div
              className={`${
                isDetailPage ? "hidden lg:block" : ""
              } w-full md:w-64 md:border-r bg-white min-h-screen relative`}
            >
              <div className="overflow-scroll absolute inset-0 mb-14">
                <MegaphoneList />
                <div className="absolute bottom-0 px-2 py-2 left-0 right-0">
                  <Link
                    to="/megaphones/new"
                    className="text-center py-2 px-4 border rounded-md text-sm leading-5 bg-gray-100 w-full block"
                  >
                    Add Megaphone
                  </Link>
                </div>
              </div>
            </div>
            <div className="hidden md:block flex-grow max-h-full overflow-auto">
              <div className="flex flex-col items-center p-4">
                {(megaphone || isNewPage) && <MegaphoneFormPreview />}
              </div>
            </div>
            <div
              className={`${
                isDetailPage ? "" : "hidden md:block"
              } w-full md:max-w-sm bg-white shadow-sm`}
              style={{
                height: "calc(100vh - 56px)",
              }}
            >
              {(megaphone || isNewPage) && <MegaphoneForm />}
            </div>
          </div>
        </I18nProvider>
      </FormProvider>
    </PageContainer>
  );
}

export default Megaphones;
