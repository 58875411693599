import React from "react";
import { Link, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import { useSoapbox } from "utils/soapboxes";
import { I18nProvider } from "utils/useI18n";
import usePrevious from "utils/usePrevious";
import SoapboxList from "components/Soapbox/SoapboxList";
import SoapboxFormPreview from "components/Soapbox/SoapboxFormPreview";
import SoapboxForm, { defaultValues } from "components/Soapbox/SoapboxForm";
import PageContainer from "components/PageContainer";

function Soapboxes() {
  const { id } = useParams();
  const soapbox = useSoapbox(id);

  const previousSoapbox = usePrevious(soapbox);
  const { reset, ...formMethods } = useForm();

  const isDetailPage = id !== undefined;
  const isNewPage = id === "new";

  React.useEffect(() => {
    if (id === "new") {
      reset(defaultValues);
    }
  }, [reset, id]);

  React.useEffect(() => {
    if (soapbox?.id !== previousSoapbox?.id) {
      reset(soapbox);
    }
  }, [reset, soapbox, previousSoapbox]);

  return (
    <PageContainer>
      <FormProvider {...formMethods}>
        <I18nProvider>
          <div className="flex bg-gray-200 max-h-screen -mt-14 pt-14 overflow-hidden">
            <div
              className={`${
                isDetailPage ? "hidden lg:block" : ""
              } w-full md:w-64 md:border-r bg-white min-h-screen relative`}
            >
              <div className="overflow-scroll absolute inset-0 mb-14">
                <SoapboxList />
                <div className="absolute bottom-0 px-2 py-2 left-0 right-0">
                  <Link
                    to="/soapboxes/new"
                    className="text-center py-2 px-4 border rounded-md text-sm leading-5 bg-gray-100 w-full block"
                  >
                    Add Soapbox
                  </Link>
                </div>
              </div>
            </div>
            <div className="hidden md:block flex-grow max-h-full overflow-auto">
              <div className="flex flex-col items-center p-4 h-full">
                {(soapbox || isNewPage) && <SoapboxFormPreview />}
              </div>
            </div>
            <div
              className={`${
                isDetailPage ? "" : "hidden md:block"
              } w-full md:max-w-sm bg-white shadow-sm`}
              style={{
                height: "calc(100vh - 56px)",
              }}
            >
              {(soapbox || isNewPage) && <SoapboxForm />}
            </div>
          </div>
        </I18nProvider>
      </FormProvider>
    </PageContainer>
  );
}

export default Soapboxes;
