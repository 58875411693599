import React from "react";
import { useFormContext } from "react-hook-form";

import MegaphonePreview from "components/Megaphone/MegaphonePreview";

const MegaphoneFormPreview = () => {
  const { watch } = useFormContext();
  const megaphone = watch();

  return <MegaphonePreview megaphone={megaphone} />;
};

export default MegaphoneFormPreview;
