import React from "react";

const availableLocales = [
  { value: "en", name: "English" },
  { value: "ar", name: "Arabic" },
  { value: "de", name: "German" },
  { value: "es", name: "Spanish" },
  { value: "fr", name: "French" },
  { value: "it", name: "Italian" },
  { value: "ja", name: "Japanese" },
  { value: "ko", name: "Korean" },
  { value: "nl", name: "Dutch" },
  { value: "pt-BR", name: "Portuguese" },
  { value: "tr", name: "Turkish" },
  { value: "ms", name: "Malay" },
  { value: "hi", name: "Hindi" },
  { value: "zh-Hans", name: "Chinese (Simplified)" },
  { value: "zh-Hant", name: "Chinese (Traditional)" },
  { value: "ru", name: "Russian" },
];

const I18nContext = React.createContext();

function I18nProvider({ children }) {
  const [locale, setLocale] = React.useState("en");

  const value = React.useMemo(() => ({ locale, setLocale, availableLocales }), [
    locale,
  ]);

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

function useI18n() {
  const context = React.useContext(I18nContext);
  if (context === undefined) {
    throw new Error(`useI18n must be used within a I18nProvicer`);
  }
  return context;
}

export { I18nProvider, useI18n };
