import React from "react";

/**
 * @typedef Props
 * @prop {React.ReactNode} children
 */

/**
 * @param {Props}
 */
const List = ({ children }) => <div className="relative">{children}</div>;

const Subheader = ({ children }) => (
  <div className="text-sm font-bold text-gray-500 bg-gray-50 px-3 py-1">
    {children}
  </div>
);

List.Subheader = Subheader;

export default List;
