import React from "react";
import { useFormContext } from "react-hook-form";
import Form from "components/Common/Form";

function Select({ name, children, defaultValue, required = false }) {
  const { register, errors } = useFormContext();

  return (
    <Form.Select
      name={name}
      ref={register({ required })}
      defaultValue={defaultValue}
      error={errors[name]}
    >
      {children}
    </Form.Select>
  );
}

export default Select;
