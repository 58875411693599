import React from "react";
import { useAuth } from "context/auth-context";

class ExpiredTokenError extends Error {
  constructor(message) {
    super(message);
    this.name = "ExpiredTokenError";
  }
}

async function client(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  return window.fetch(endpoint, config).then(async (response) => {
    if (response.status === 204) return "";
    if (response.status === 403) return Promise.reject(new ExpiredTokenError());
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

function useClient() {
  const { getToken, forceRefreshToken } = useAuth();

  return React.useCallback(
    (endpoint, config) => {
      const token = getToken();
      return client(endpoint, { ...config, token })
        .then((data) => data)
        .catch(async (err) => {
          if (err instanceof ExpiredTokenError) {
            await forceRefreshToken();
            return Promise.reject({ message: "Invalid token" });
          }
          return err;
        });
    },
    [getToken, forceRefreshToken]
  );
}

export { useClient };
