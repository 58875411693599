import React from "react";
import BuildSummary from "../components/BuildSummary";
import BuildDetails from "../components/BuildDetails";
import { useParams } from "react-router-dom";
import useFetch from "../utils/useFetch";
import PageContainer from "../components/PageContainer";
import "./Builds.css";

function AllBuilds(props) {
  let params = useParams();
  let allBuilds = props.builds;

  if (params.branch !== undefined) {
    let build = allBuilds.find((element) => element.key === params.branch);

    if (!build) {
      return <p> No build found for {params.branch}</p>;
    }
    return <BuildDetails build={build} />;
  }

  const integrationBranches = allBuilds.filter((build) => {
    const isIntegrationBranch =
      build.linearTask &&
      build.linearTask.labels &&
      build.linearTask.labels.nodes &&
      build.linearTask.labels.nodes.filter((o) => o.name === "integration")
        .length > 0;
    return (
      build.branch === "release" ||
      build.branch === "develop" ||
      isIntegrationBranch
    );
  });

  integrationBranches.sort((a, b) => {
    let left = a.createdAt === undefined ? 0 : parseInt(a.createdAt);
    let right = b.createdAt === undefined ? 0 : parseInt(b.createdAt);
    return right - left;
  });

  const regularBranches = allBuilds.filter(
    (e) => !integrationBranches.includes(e)
  );

  regularBranches.sort((a, b) => {
    let left = a.createdAt === undefined ? 0 : parseInt(a.createdAt);
    let right = b.createdAt === undefined ? 0 : parseInt(b.createdAt);
    return right - left;
  });

  const integrationLinks = integrationBranches.map((build) => (
    <BuildSummary key={build.key} build={build} />
  ));

  const regularLinks = regularBranches.map((build) => (
    <BuildSummary key={build.key} build={build} />
  ));

  return (
    <div>
      <div className="listHeader">Integration Branches</div> {integrationLinks}
      <div className="listHeader">Standard Branches</div>
      {regularLinks}
    </div>
  );
}

function Builds(props) {
  const [data, error] = useFetch("/api/builds");

  let content = null;

  if (data === null && error === null) {
    content = <div>Loading...</div>;
  } else if (error !== null) {
    content = <div>Error: {error} </div>;
  } else {
    const builds = data.buildDetails;
    content = <AllBuilds builds={builds} />;
  }

  return <PageContainer>{content}</PageContainer>;
}

export default Builds;
