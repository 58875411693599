import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import Form from "components/Common/Form";
import Checkbox from "components/Form/Checkbox";
import FileUpload from "components/Form/FileUpload";
import I18nInput from "components/Form/I18nInput";
import Select from "components/Form/Select";
import TextInput from "components/Form/TextInput";
import { MinusCircleIcon } from "@heroicons/react/outline";

const InputArray = ({ name, schema, itemLabel }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const handleAddButtonClick = () => {
    const field = Object.keys(schema).reduce(
      (previousvalue, currentValue) => ({
        [currentValue]: "",
        ...previousvalue,
      }),
      {}
    );
    append(field);
  };

  const toTitleCase = function (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div>
      <div className="inline-flex w-full py-2 px-1 border-b">
        <Form.Label>{toTitleCase(name)}</Form.Label>
        <button
          className="ml-auto rounded-full w-8 h-8 font-bold"
          onClick={handleAddButtonClick}
        >
          +
        </button>
      </div>
      <div className={"bg-gray-50 overflow-hidden"}>
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="p-2 border-b flex flex-row space-x-2 items-center"
          >
            <div className="flex flex-col space-y-1 flex-1">
              {Object.keys(schema).map((schemaKey) => {
                const { type, required = false } = schema[schemaKey];
                if (type === "i18nText") {
                  return (
                    <I18nInput
                      key={schemaKey}
                      name={`${name}[${index}].${schemaKey}`}
                      defaultValue={field[schemaKey]}
                      placeholder={schemaKey}
                      required={required}
                    />
                  );
                }

                if (type === "checkbox") {
                  return (
                    <Form.InlineLabel label={schemaKey} key={schemaKey}>
                      <Checkbox
                        name={`${name}[${index}].${schemaKey}`}
                        defaultChecked={Boolean(field[schemaKey])}
                      />
                    </Form.InlineLabel>
                  );
                }

                if (type === "select") {
                  return (
                    <Select
                      key={schemaKey}
                      name={`${name}[${index}].${schemaKey}`}
                      defaultValue={field[schemaKey]}
                    >
                      {schema[schemaKey].options.map((option) => (
                        <option key={option}>{option}</option>
                      ))}
                    </Select>
                  );
                }

                if (type === "file") {
                  return (
                    <FileUpload
                      key={schemaKey}
                      name={`${name}[${index}].${schemaKey}`}
                      defaultValue={field[schemaKey]}
                      placeholder={schemaKey}
                    />
                  );
                }

                return (
                  <TextInput
                    key={schemaKey}
                    name={`${name}[${index}].${schemaKey}`}
                    defaultValue={field[schemaKey]}
                    placeholder={schemaKey}
                    required={required}
                  />
                );
              })}
            </div>
            <div>
              <button className="text-gray-400" onClick={() => remove(index)}>
                <MinusCircleIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputArray;
