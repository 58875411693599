import React from "react";
import { storage } from "utils/firebase";
import { v4 as uuidv4 } from "uuid";

function delay(t, v) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

function keepTrying(triesRemaining, storageRef) {
  if (triesRemaining < 0) {
    return Promise.reject("out of tries");
  }

  return storageRef
    .getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch((error) => {
      switch (error.code) {
        case "storage/object-not-found":
          return delay(2000).then(() => {
            return keepTrying(triesRemaining - 1, storageRef);
          });
        default:
          console.log(error);
          return Promise.reject(error);
      }
    });
}

const extensionRegex = /(?:\.([^.]+))?$/;
const imageMimeTypeRegex = /image\/(.*)/;
const imgSize = "1024x1024";

export default function useFileUpload() {
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState("idle");
  const [progress, setProgress] = React.useState(0);

  const upload = React.useCallback((file) => {
    const id = uuidv4();
    const ext = extensionRegex.exec(file.name)[0];
    const isImage = imageMimeTypeRegex.test(file.type);
    const uploadTask = storage.ref("uploads/").child(`${id}${ext}`).put(file);
    setStatus("loading");
    setProgress(0);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      },
      (_error) => {
        setStatus("error");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (!isImage) {
            setData(downloadURL);
            setStatus("success");
          } else {
            const storageRef = storage
              .ref()
              .child(`uploads/sizes/${id}_${imgSize}${ext}`);
            keepTrying(10, storageRef).then((url) => {
              setData(url);
              setStatus("success");
            });
          }
        });
      }
    );
  }, []);

  return {
    data,
    upload,
    status,
    progress,
    isLoading: status === "loading",
  };
}
