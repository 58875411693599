import { useQuery, useMutation, useQueryClient } from "react-query";

import { useClient } from "./api-client";

function useSoapboxes() {
  const client = useClient();

  return useQuery({
    queryKey: "soapboxes",
    queryFn: () => client("/api/soapboxes"),
  });
}

function useSoapbox(id) {
  const { data: soapboxes } = useSoapboxes();
  return soapboxes?.find((item) => item.id === id) ?? null;
}

function useCreateSoapbox(options) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (updates) => {
      return client(`/api/soapboxes`, {
        method: "POST",
        data: updates,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("soapboxes");
        if (options.onSuccess) {
          options.onSuccess(data);
        }
      },
    }
  );
}

function useUpdateSoapbox() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, ...updates }) => {
      return client(`/api/soapboxes/${id}`, {
        method: "PUT",
        data: updates,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("soapboxes");
      },
    }
  );
}

function useRemoveSoapbox() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }) => client(`/api/soapboxes/${id}`, { method: "DELETE" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("soapboxes");
      },
    }
  );
}

export {
  useSoapboxes,
  useSoapbox,
  useCreateSoapbox,
  useUpdateSoapbox,
  useRemoveSoapbox,
};
