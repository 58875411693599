import React from "react";
import LoginButton from "components/Auth/LoginButton";
import PageContainer from "../components/PageContainer";
import { useAuth } from "context/auth-context";
import { Redirect } from "react-router";

const LoginPage = () => {
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <PageContainer>
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm">
          <LoginButton />
        </div>
      </div>
    </PageContainer>
  );
};

export default LoginPage;
