/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./BuildDetails.css";
import { isMobile } from "react-device-detect";
import DeleteBuild from "./DeleteBuild";
import { useHistory } from "react-router-dom";
import timeDifference from "../utils/Time";

function GithubHeader(props) {
  return (
    <div className="header">
      <img src="/github.jpg" />
      <h1>{props.branchName}</h1>
    </div>
  );
}

function LinearHeader(props) {
  const task = props.build.linearTask;
  return (
    <div className="linearHeader">
      <img src="/linear.jpg" />
      <div>
        <h1>
          <a href={task.url}>{task.title}</a>
        </h1>
        <h4>{props.build.branch}</h4>
      </div>
    </div>
  );
}

function PullRequest(props) {
  const nodes = props.build.github.commit.associatedPullRequests.nodes;
  if (nodes.length === 0) {
    return <div></div>;
  }

  const pullRequest = nodes[0];

  const status = descriptionForDecision(
    pullRequest.reviewDecision,
    pullRequest.state
  );

  return (
    <div className="pullRequestDetails contentBlock">
      <h4>Pull Request</h4>
      <div className="pullRequestBody">
        <img src="/pr.jpg" />
        <div>
          <h2>
            <a href={pullRequest.permalink}>{pullRequest.title}</a>
          </h2>
          <p className="detail">
            #{pullRequest.number} by {pullRequest.author.login}
            {status ? " • " + status : ""}
          </p>
        </div>
      </div>
    </div>
  );
}

function Commit(props) {
  return (
    <div className="commitDetails contentBlock">
      <h4>Commit</h4>
      <h2>
        <a href={props.build.github.commit.commitUrl}>
          {props.build.github.commit.messageHeadline}
        </a>
      </h2>
      <p className="detail">
        Commited by {props.build.github.commit.author.name}{" "}
        {timeDifference(
          Date.now(),
          Date.parse(props.build.github.commit.committedDate)
        )}
      </p>
    </div>
  );
}

function BuildLink(props) {
  if (isMobile) {
    return (
      <a className="buildLink" href={props.build.iosManifestURL}>
        Install
      </a>
    );
  } else {
    return (
      <a className="buildLink" href={props.build.macDownloadURL}>
        Download
      </a>
    );
  }
}

function BuildDetails(props) {
  const build = props.build;
  const history = useHistory();
  return (
    <div>
      <div className="listHeader">
        <div
          id="backButton"
          onClick={(e) => {
            history.push("/builds");
          }}
        >
          <img src="/back.png" />
          <span>All Builds</span>
        </div>
      </div>
      <div className="details">
        {build.linearTask !== undefined ? (
          <LinearHeader build={build} />
        ) : (
          <GithubHeader branchName={build.branch} />
        )}
        <PullRequest build={build} />
        <Commit build={build} />
        <BuildLink build={build} />
        <DeleteBuild build={build} />
      </div>
    </div>
  );
}

function descriptionForDecision(decision, state) {
  if (decision === null || state !== "OPEN") {
    switch (state) {
      case "CLOSED":
        return "Closed";
      case "MERGED":
        return "Merged";
      default:
        break;
    }
  }

  switch (decision) {
    case "APPROVED":
      return "Approved";
    case "CHANGES_REQUESTED":
      return "Changes Requested";
    case "REVIEW_REQUIRED":
      return "Review Required";

    default:
      break;
  }
}

export default BuildDetails;
