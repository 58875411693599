import { useQuery, useMutation, useQueryClient } from "react-query";

import { useClient } from "./api-client";

function useMegaphones() {
  const client = useClient();

  return useQuery({
    queryKey: "megaphones",
    queryFn: () => client("/api/megaphones"),
  });
}

function useMegaphone(id) {
  const { data: megaphones } = useMegaphones();
  return megaphones?.find((item) => item.id === id) ?? null;
}

function useCreateMegaphone(options) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (updates) => {
      return client(`/api/megaphones`, {
        method: "POST",
        data: updates,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("megaphones");
        if (options.onSuccess) {
          options.onSuccess(data);
        }
      },
    }
  );
}

function useUpdateMegaphone() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, ...updates }) => {
      return client(`/api/megaphones/${id}`, {
        method: "PUT",
        data: updates,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("megaphones");
      },
    }
  );
}

function useRemoveMegaphone() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }) => client(`/api/megaphones/${id}`, { method: "DELETE" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("megaphones");
      },
    }
  );
}

export {
  useMegaphone,
  useMegaphones,
  useCreateMegaphone,
  useUpdateMegaphone,
  useRemoveMegaphone,
};
