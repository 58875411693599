import React from "react";
import { useFormContext } from "react-hook-form";

import SoapboxPreview from "components/Soapbox/SoapboxPreview";

const SoapboxFormPreview = () => {
  const { watch } = useFormContext();
  const soapbox = watch();

  return <SoapboxPreview soapbox={soapbox} />;
};

export default SoapboxFormPreview;
