import React, { Fragment } from "react";
import { useI18n } from "utils/useI18n";
import TextInput from "components/Form/TextInput";

function I18nInput({
  name,
  defaultValue,
  placeholder = "",
  required = false,
  textarea = false,
}) {
  const { locale, availableLocales } = useI18n();

  return (
    <Fragment>
      <div className={`${locale === "en" ? "" : "hidden"}`}>
        <TextInput
          name={`${name}.value`}
          defaultValue={defaultValue?.value}
          required={required}
          as={textarea ? "textarea" : "text"}
          placeholder={placeholder}
        />
      </div>
      {availableLocales
        .filter(({ value }) => value !== "en")
        .map(({ value }) => (
          <div key={value} className={`${locale === value ? "" : "hidden"}`}>
            <TextInput
              name={`${name}.i18n.${value}`}
              defaultValue={defaultValue?.i18n?.[value]}
              as={textarea ? "textarea" : "text"}
              placeholder={placeholder}
            />
          </div>
        ))}
    </Fragment>
  );
}

export default I18nInput;
