import React from "react";
import PageContainer from "../components/PageContainer";
import { auth } from "../utils/firebase";

export default function Home() {
  return (
    <PageContainer>
      <div>
        <button onClick={() => auth.signOut()}>Sign out</button>
      </div>
    </PageContainer>
  );
}
