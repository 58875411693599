import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function formatDate(date) {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function parseDate(dateStr) {
  const parts = /^(\d{4})-(\d{2})-(\d{2})/.exec(dateStr);
  return new Date(parts[1], parseInt(parts[2], 10) - 1, parts[3]);
}

function DateRange({ name }) {
  const { control } = useFormContext();

  return (
    <div className="border border-gray-300 bg-gray-50 rounded-md p-2 flex flex-col">
      <>
        <Controller
          control={control}
          name={`${name}.startDate`}
          render={({ onChange, value, ref }) => (
            <DatePicker
              selected={value ? parseDate(value) : undefined}
              onChange={(date) => {
                if (date) {
                  onChange(formatDate(date));
                } else {
                  onChange(date);
                }
              }}
              dateFormat="yyyy-MM-dd"
              className="block w-full sm:text-sm border rounded-md px-2 py-2 border-gray-300"
              placeholderText="Start Date"
              ref={ref}
            />
          )}
          defaultValue={null}
        />
        <Controller
          control={control}
          name={`${name}.endDate`}
          render={({ onChange, value, ref }) => (
            <DatePicker
              selected={value ? parseDate(value) : undefined}
              onChange={(date) => {
                if (date) {
                  onChange(formatDate(date));
                } else {
                  onChange(date);
                }
              }}
              dateFormat="yyyy-MM-dd"
              className="block w-full sm:text-sm border rounded-md px-2 py-2 border-gray-300 mt-1"
              placeholderText="End Date"
              ref={ref}
            />
          )}
          defaultValue={null}
        />
      </>
    </div>
  );
}

export default DateRange;
