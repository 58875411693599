import React from "react";
import { useFormContext } from "react-hook-form";

import useFileUpload from "utils/useFileUpload";

const FileUpload = React.forwardRef(({ name, ...rest }, ref) => {
  const { setValue, register } = useFormContext();
  const { data, upload, isLoading, progress } = useFileUpload();

  function handleChange(event) {
    const file = event.target.files[0];
    if (file) {
      upload(file);
    }
  }

  React.useEffect(() => {
    if (data) {
      setValue(name, data);
    }
  }, [name, data, setValue]);

  return (
    <div className="flex">
      <input
        type="text"
        name={name}
        ref={register()}
        className="flex-1 block w-full sm:text-sm border-0 appearance-none px-2 py-2 bg-gray-100"
        autoComplete="off"
        {...rest}
      />
      <input
        onChange={handleChange}
        type="file"
        id={`${name}-input`}
        className="hidden"
      />
      <label
        htmlFor={`${name}-input`}
        className="inline-flex items-center px-3 border-0 bg-gray-200 text-gray-500 font-semibold text-sm"
      >
        {isLoading ? `Uploading…${Math.ceil(progress * 100)}%` : "Choose file"}
      </label>
    </div>
  );
});

export default FileUpload;
