import React from "react";

const Button = ({ full = false, ...rest }) => (
  <button
    className={`
      py-2 px-4 border text-sm leading-5 text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-700 border-blue-600
      ${full ? "w-full" : ""}
    `}
    {...rest}
  />
);

export default Button;
