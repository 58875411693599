import React from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Button from "components/Common/Button";
import Form from "components/Common/Form";
import Tabs from "components/Common/Tabs";
import Checkbox from "components/Form/Checkbox";
import DateRange from "components/Form/DateRange";
import FileUpload from "components/Form/FileUpload";
import TextInput from "components/Form/TextInput";
import I18nInput from "components/Form/I18nInput";
import { useCreateMegaphone, useUpdateMegaphone } from "utils/megaphones";
import { useI18n } from "utils/useI18n";

export const defaultValues = {
  id: "",
  title: { value: "" },
  body: { value: "" },
  url: "",
  image: "",
  conditionals: {
    subscribers: true,
    build: "",
    numExports: "",
    numSessions: "",
  },
};

const MegaphoneForm = () => {
  const history = useHistory();
  const { locale, setLocale, availableLocales } = useI18n();
  const { mutate: updateMegaphone } = useUpdateMegaphone();
  const { mutate: createMegaphone } = useCreateMegaphone({
    onSuccess: (data) => {
      history.push(`/megaphones/${data.id}`);
    },
  });
  const { handleSubmit, register } = useFormContext();

  function onSubmit(data) {
    if (data.id) {
      updateMegaphone(data);
    } else {
      createMegaphone(data);
    }
  }

  return (
    <div className="min-h-full max-h-full relative">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-scroll absolute inset-0 mb-14 p-2">
          <Tabs>
            <Tabs.Tab label="Content">
              <input type="hidden" name="id" ref={register()} />
              <div className="mb-4">
                <Form.Label>Title</Form.Label>
                <I18nInput name="title" placeholder="title" required />
              </div>
              <div className="mb-4">
                <Form.Label>Body</Form.Label>
                <I18nInput name="body" textarea />
              </div>
              <div className="mb-4">
                <Form.Label>Image</Form.Label>
                <FileUpload name="image" />
              </div>
              <div className="mb-4">
                <Form.Label>URL</Form.Label>
                <TextInput name="url" />
              </div>
            </Tabs.Tab>
            <Tabs.Tab label="Config">
              <div className="mb-4">
                <Form.Label>Build</Form.Label>
                <TextInput name="conditionals.build" />
              </div>
              <div className="mb-4">
                <Form.Label>Number of Exports</Form.Label>
                <TextInput name="conditionals.numExports" />
              </div>
              <div className="mb-4">
                <Form.Label>Number of Sessions</Form.Label>
                <TextInput name="conditionals.numSessions" />
              </div>
              <div className="mb-4">
                <Form.Label>Date Range</Form.Label>
                <DateRange name="conditionals.dateRange" />
              </div>
              <div className="mb-4">
                <Form.InlineLabel label="Visible to subscribers">
                  <Checkbox name="conditionals.subscribers" defaultChecked />
                </Form.InlineLabel>
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
        <div className="absolute flex items-center justify-between bottom-0 w-full px-2 py-2 bg-gray-50">
          <div className="text-sm">
            View in
            <select
              value={locale}
              onChange={(event) => setLocale(event.target.value)}
              className="bg-gray-50 border-0 text-sm"
            >
              {availableLocales.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </div>
  );
};

export default MegaphoneForm;
