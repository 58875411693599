/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import Spinner from "components/Common/Spinner";
import { useHistory } from "react-router-dom";
import "./DeleteBuild.css";

export default function DeleteBuild(props) {
  const build = props.build;
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  function performDelete(e) {
    if (
      window.confirm(
        "Are you sure you want to delete the builds for " + build.branch + "?"
      )
    ) {
      try {
        setIsDeleting(true);
        fetch("/api/builds/" + build.key + "/" + build.obfuscatedKey, {
          method: "DELETE",
        })
          .then((res) => res.text())
          .then((res) => {
            setIsDeleting(false);
            history.push("/builds");
          });
      } catch (error) {
        console.log("encountered an error " + error);
        setIsDeleting(false);
      }
    }
  }

  return (
    <div className="deleteBuild">
      {isDeleting ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <a
          href="#"
          role="button"
          className="deleteAction"
          onClick={performDelete}
        >
          Delete Build
        </a>
      )}
    </div>
  );
}
