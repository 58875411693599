import React from "react";
import ReactMarkdown from "react-markdown";
import { useI18n } from "utils/useI18n";

/**
 * @typedef Props
 * @prop {import('darkroom-api/types').SoapboxResource} soapbox
 */

/**
 * @param {Props}
 */
const SoapboxPreview = ({ soapbox }) => {
  const {
    title,
    showXButton,
    includeIAPFooter,
    showDesignAward,
    centerAlignTitle,
    useCompactVerticalSpacing,
    text,
    carousel,
    offers,
    content,
    buttons,
    headerImage,
  } = soapbox;
  const { locale } = useI18n();

  function i18nText(value) {
    return value?.i18n?.[locale] || value?.value;
  }

  return (
    <div className="bg-white w-full max-w-sm rounded-2xl overflow-hidden relative shadow-xl m-auto">
      {headerImage && <img src={headerImage} className="w-full" alt="" />}
      {showXButton && (
        <button
          className="text-gray-500 w-8 h-8 absolute m-2 left-0 top-0 bg-gray-200 rounded-full"
          disabled
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
      {title && (
        <div className="px-8 pt-3">
          <div className="flex-grow text-center text-xl font-bold leading-relaxed">
            {i18nText(title)}
          </div>
        </div>
      )}
      <div className="flex flex-col space-y-4 p-4">
        {text && (
          <div className="text-center my-2 text-sm leading-relaxed">
            <ReactMarkdown>{i18nText(text)}</ReactMarkdown>
          </div>
        )}
        {content && (
          <div className="flex space-y-4 flex-col">
            {content.map((item, index) => (
              <div className="flex" key={index}>
                {item.image && (
                  <img src={item.image} className="w-16 h-16 mr-2" alt="" />
                )}
                <div className="flex-1">
                  <div className="text-lg font-semibold leading-none">
                    <ReactMarkdown>{i18nText(item.title)}</ReactMarkdown>
                  </div>
                  <div className="text-sm leading-snug">
                    <ReactMarkdown>{i18nText(item.text)}</ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {carousel && (
          <div className="flex flex-nowrap space-x-2 my-4 overflow-x-scroll">
            {carousel.map((item, index) => (
              <div
                className="bg-gray-100 w-40 h-32 flex items-end relative rounded-xl overflow-hidden shadow-sm flex-shrink-0"
                key={index}
              >
                {item.video && (
                  <video
                    src={item.video}
                    className="absolute inset-0 h-full w-full object-cover"
                    muted
                    autoPlay
                  />
                )}
                {item.image && (
                  <img
                    src={item.image}
                    className="absolute inset-0 h-full object-cover"
                    alt=""
                  />
                )}
                <span className="text-white font-semibold leading-tight relative italic text-xl m-2">
                  {i18nText(item.title)}
                </span>
              </div>
            ))}
          </div>
        )}
        {offers && (
          <div className="flex flex-col space-y-1">
            {offers.map((item, index) => (
              <div
                className="bg-gray-100 rounded-lg h-12 p-2 flex items-center"
                key={index}
              >
                <div className="w-5 h-5 border-2 rounded-full mr-2 border-gray-300">
                  {item.preselected && (
                    <div className="w-full h-full bg-black rounded-full border-2 border-gray-100"></div>
                  )}
                </div>
                <div>
                  <div className="bg-gray-200 w-32 h-4 mb-1"></div>
                  <div className="bg-gray-200 w-24 h-3"></div>
                </div>
                {item.discountText &&
                  i18nText(item.discountText).length > 0 && (
                    <div className="bg-red-600 ml-auto text-white text-xs italic font-semibold px-2 py-1 rounded-sm">
                      {i18nText(item.discountText)}
                    </div>
                  )}
              </div>
            ))}
          </div>
        )}
        {buttons && (
          <div className="flex flex-col space-y-2">
            {buttons.map((item, index) => (
              <div
                className={`py-2 px-4 rounded-md leading-7 text-center ${item.style === "Primary"
                  ? "bg-red-600 text-white font-semibold"
                  : "bg-gray-200 text-gray-500"
                  }`}
                key={index}
              >
                {i18nText(item.title)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SoapboxPreview;
