import React from "react";

function ListItem({ children, component = "div", className = "", ...rest }) {
  const Component = component;
  return (
    <Component
      className={`flex items-center justify-between hover:no-underline ${className}`}
      {...rest}
    >
      {children}
    </Component>
  );
}

function Content({ children }) {
  return <div className="py-2 px-3">{children}</div>;
}
function Title({ children }) {
  return <div className="text-base font-bold text-gray-800">{children}</div>;
}
function Subtitle({ children }) {
  return <div className="text-sm text-gray-500">{children}</div>;
}
function Action({ children }) {
  return <div className="py-2 px-3">{children}</div>;
}

ListItem.Content = Content;
ListItem.Title = Title;
ListItem.Subtitle = Subtitle;
ListItem.Action = Action;

export default ListItem;
