import React from "react";
import { useController } from "react-hook-form";
import Form from "components/Common/Form";

function TextInput({
  name,
  defaultValue = "",
  placeholder = "",
  required = false,
  as = "text",
}) {
  const {
    field: { ref, value, ...inputProps },
    meta: { invalid },
  } = useController({ name, rules: { required }, defaultValue: defaultValue });

  return (
    <Form.TextInput
      value={value || ""}
      ref={ref}
      {...inputProps}
      placeholder={placeholder}
      as={as}
      error={invalid}
    />
  );
}

export default TextInput;
