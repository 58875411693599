import React from "react";
import { Route } from "react-router-dom";

import Home from "./pages/Home";
import Builds from "./pages/Builds";
import Megaphones from "./pages/Megaphones";
import Soapboxes from "./pages/Soapboxes";
import Login from "./pages/Login";
import PrivateRoute from "./utils/PrivateRoute";

const App = () => {
  return (
    <>
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/builds" component={Builds} />
      <PrivateRoute exact path="/builds/:branch" component={Builds} />
      <PrivateRoute exact path="/megaphones/:id?" component={Megaphones} />
      <PrivateRoute exact path="/soapboxes/:id?" component={Soapboxes} />
    </>
  );
};

export default App;
