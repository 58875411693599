import React from "react";
import { useFormContext } from "react-hook-form";
import Form from "components/Common/Form";

function Checkbox({ name, defaultChecked }) {
  const { register } = useFormContext();

  return (
    <Form.Checkbox
      name={name}
      ref={register()}
      defaultChecked={defaultChecked}
    />
  );
}

export default Checkbox;
