/* eslint-disable jsx-a11y/alt-text */

import React from "react";
import { useHistory } from "react-router-dom";
import "./BuildSummary.css";
import timeDifference from "../utils/Time";

function Title(props) {
  let title = props.build.linearTask
    ? props.build.linearTask.title
    : props.build.branch;

  let number = undefined;

  if (props.build.linearTask && props.build.linearTask.number) {
    number = props.build.linearTask.number;
  } else if (
    props.build.github &&
    props.build.github.commit &&
    props.build.github.commit.associatedPullRequest &&
    props.build.github.commit.associatedPullRequest.nodes &&
    props.build.github.commit.associatedPullRequest.nodes[0]
  ) {
    number = props.build.github.commit.associatedPullRequest.nodes[0].number;
  }

  if (number) {
    return (
      <div className="content">
        <span className="source-number">{"(#" + number + ")"}</span>
        <span className="source-title">{title}</span>
      </div>
    );
  } else {
    return (
      <div className="content">
        <span className="source-title">{title}</span>
      </div>
    );
  }
}

function CreatedAt(props) {
  if (props.build.createdAt === undefined) return <></>;

  const difference = timeDifference(
    Date.now(),
    parseInt(props.build.createdAt)
  );
  return <div className="content createdAt">{difference}</div>;
}

function BuildAvatar(props) {
  return (
    <div className="content sourceImage">
      <img
        src={
          props.build.linearTask === undefined ? "/github.jpg" : "/linear.jpg"
        }
      />
    </div>
  );
}

function UserAvatar(props) {
  let avatarUrl = undefined;

  if (
    props.build.linearTask &&
    props.build.linearTask.assignee &&
    props.build.linearTask.assignee.avatarUrl
  ) {
    avatarUrl = props.build.linearTask.assignee.avatarUrl;
  } else if (
    props.build.github &&
    props.build.github.commit &&
    props.build.github.commit.author &&
    props.build.github.commit.author.avatarUrl
  ) {
    avatarUrl = props.build.github.commit.author.avatarUrl;
  }

  if (avatarUrl) {
    return (
      <div className="content avatarImage">
        <img src={avatarUrl} />
      </div>
    );
  }

  return <></>;
}

function BuildSummary(props) {
  const build = props.build;
  const history = useHistory();

  return (
    <li
      className="buildSummary"
      onClick={(e) => {
        history.push("/builds/" + build.key);
      }}
    >
      <BuildAvatar build={build} />
      <Title build={build} />
      <div className="rightInformation">
        <CreatedAt build={build} />
        <UserAvatar build={build} />
      </div>
    </li>
  );
}

export default BuildSummary;
