import React from "react";

function Tabs({ children }) {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  function onTabClick(index) {
    setActiveTabIndex(index);
  }

  return (
    <div>
      <div className="flex space-x-2 border-b border-gray-100">
        {children.map((tab, index) => {
          const active = activeTabIndex === index;
          return (
            <button
              key={index}
              onClick={() => onTabClick(index)}
              tabIndex={active ? 0 : -1}
              className={`${
                active ? "border-gray-200" : ""
              } text-sm px-1 py-1 focus:outline-none text-gray-500 border-b-2 border-transparent hover:text-gray-900`}
              style={{ marginBottom: "-1px" }}
              type="button"
            >
              {tab.props.label}
            </button>
          );
        })}
      </div>
      {children.map((tab, index) => {
        const active = activeTabIndex === index;
        return (
          <div key={index} className={`py-4 ${active ? "block" : "hidden"}`}>
            {tab.props.children}
          </div>
        );
      })}
    </div>
  );
}

function Tab({ children }) {
  return <div>{children}</div>;
}

Tabs.Tab = Tab;

export default Tabs;
