import React from "react";
import firebase, { auth } from "utils/firebase.js";

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);
  const tokenRef = React.useRef(null);

  const login = React.useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithPopup(provider);
  }, []);

  const forceRefreshToken = React.useCallback(async () => {
    tokenRef.current = await auth?.currentUser?.getIdToken(true);
    setAuthenticated(!!tokenRef.current);
  }, []);

  const getToken = React.useCallback(() => {
    return tokenRef.current;
  }, []);

  const handleAuthChange = React.useCallback(async (user) => {
    tokenRef.current = await user?.getIdToken();
    setAuthenticated(!!tokenRef.current);
    setIsLoading(false);
  }, []);

  const value = React.useMemo(
    () => ({ authenticated, forceRefreshToken, getToken, login }),
    [authenticated, forceRefreshToken, getToken, login]
  );

  React.useEffect(() => {
    auth.onAuthStateChanged(handleAuthChange);
  }, [handleAuthChange]);

  if (isLoading) {
    return <>Loading…</>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, AuthContext, useAuth };
