import React from "react";
import { Link } from "react-router-dom";
import List from "components/Common/List";
import ListItem from "components/Common/ListItem";
import { useMegaphones, useRemoveMegaphone } from "utils/megaphones";

function MegaphoneList() {
  const { data: items = [] } = useMegaphones();
  const { mutate: handleDelete } = useRemoveMegaphone();

  return (
    <List>
      {items.map((item) => (
        <ListItem
          key={item.id}
          component={Link}
          to={`/megaphones/${item.id}`}
          className="group"
        >
          <ListItem.Content>
            <ListItem.Title>{item.title.value}</ListItem.Title>
            <ListItem.Subtitle>
              {Boolean(item.conditionals.build) &&
                `Build ${item.conditionals.build}`}
            </ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Action>
            <button
              type="button"
              className="md:invisible group-hover:visible"
              onClick={(event) => {
                event.preventDefault();
                const confirm = window.confirm("Are you sure?");
                if (confirm) {
                  handleDelete(item);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </ListItem.Action>
        </ListItem>
      ))}
    </List>
  );
}

export default MegaphoneList;
