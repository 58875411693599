import React from "react";
import ReactMarkdown from "react-markdown";
import { useI18n } from "utils/useI18n";

/**
 * @typedef Props
 * @prop {import('darkroom-api/types').MegaphoneResource} megaphone
 */

/**
 * @param {Props}
 */
const MegaphonePreview = ({ megaphone }) => {
  const { title, body, image } = megaphone;
  const { locale } = useI18n();

  function i18nText(value) {
    return value?.i18n?.[locale] || value?.value;
  }

  return (
    <div className="bg-white w-full max-w-sm rounded-2xl overflow-hidden flex items-center">
      {image && <img src={image} className="w-20 h-20" alt="" />}
      <div className="px-4">
        {title && <div className="font-semibold mb-1">{i18nText(title)}</div>}
        {body && (
          <div className="text-sm">
            <ReactMarkdown>{i18nText(body)}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default MegaphonePreview;
